import Vue from 'vue'
import App from './App.vue'
import VueI18n from "vue-i18n"
import router from './router'
import Mixins from '@/Mixins'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './styles/myselfie.css'
import daLang from './localization/da'
import svLang from './localization/sv'
import enLang from './localization/en'
import deLang from './localization/de'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.mixin(Mixins)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueI18n)

Vue.config.productionTip = false

const messages = {
    en : enLang,
    da : daLang, 
    sv: svLang,
    de: deLang
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

router.beforeEach((to, from, next) => {
    let locale = to.query.lang ?? localStorage.lang;
    if (!locale) {
        locale = 'en';
    }
    else {
        locale = locale === 'se' ? 'sv' : locale
    }

    localStorage.lang = locale;
    
    i18n.locale = locale;
    next();
});

new Vue({
  router,
  i18n,  
  render: h => h(App)
}).$mount('#app')



